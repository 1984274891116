.error-404 {
  h1 {
    font-size: 280px;
    line-height: 280px;
    margin-bottom: 30px;
    color: $gray-8;
    -webkit-text-fill-color: $white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $gray-8;
    -ms-text-fill-color: $white;
    -ms-text-stroke-width: 1px;
    -ms-text-stroke-color: $gray-8;
  }

  strong {
    font-size: 30px;
    line-height: 30px;
    color: $gray-8;
    display: block;
    margin-bottom: 20px;
  }

  span {
    font-size: 16px;
    font-weight: 600;
  }

}

.house-animation {
  animation: animationFrames ease-out 15s;
  animation-iteration-count: infinite;
  transform-origin: 50% 0%;
  -webkit-animation: animationFrames ease-out 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 0%;
  -moz-animation: animationFrames ease-out 15s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 0%;
  -o-animation: animationFrames ease-out 15s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 0%;
  -ms-animation: animationFrames ease-out 15s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 0%;
}

@keyframes animationFrames {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }

}

@-moz-keyframes animationFrames {
  0% {
    -moz-transform: rotate(0deg);
  }

  20% {
    -moz-transform: rotate(15deg);
  }

  40% {
    -moz-transform: rotate(-10deg);
  }

  60% {
    -moz-transform: rotate(5deg);
  }

  80% {
    -moz-transform: rotate(-5deg);
  }

  100% {
    -moz-transform: rotate(0deg);
  }

}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: rotate(0deg);
  }

  20% {
    -webkit-transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }

}

@-o-keyframes animationFrames {
  0% {
    -o-transform: rotate(0deg);
  }

  20% {
    -o-transform: rotate(15deg);
  }

  40% {
    -o-transform: rotate(-10deg);
  }

  60% {
    -o-transform: rotate(5deg);
  }

  80% {
    -o-transform: rotate(-5deg);
  }

  100% {
    -o-transform: rotate(0deg);
  }

}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform: rotate(0deg);
  }

  20% {
    -ms-transform: rotate(15deg);
  }

  40% {
    -ms-transform: rotate(-10deg);
  }

  60% {
    -ms-transform: rotate(5deg);
  }

  80% {
    -ms-transform: rotate(-5deg);
  }

  100% {
    -ms-transform: rotate(0deg);
  }

}

.cloud {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation: movecloud 4s linear infinite;  /*animation-play-state: paused;*/
}

.cloud-01 {
  fill: red;
  top: 10px;
  animation-delay: -1s;
  animation-duration: 10s;
}

.cloud-02 {
  fill: blue;
  top: 80px;
  animation-delay: -2s;
  animation-duration: 6s;
}

.cloud-03 {
  fill: green;
  top: 40px;
  animation-delay: -3s;
  animation-duration: 8s;
}

.cloud-04 {
  fill: green;
  top: 80px;
  animation-delay: -4s;
  animation-duration: 10s;
}

@keyframes movecloud {
  0% {
    left: -300px;
  }

  100% {
    left: 110%;
  }

}
