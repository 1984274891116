// sidebar
.profile-sidebar {
  position: relative;
  padding: 20px;

  .profile-avatar {
    position: relative;
    text-align: center;
    padding: 30px 0;
    width: 80px;
  }

  .profile-nav {
    position: relative;

    .nav {
      padding: 10px;
      background: $primary;

      .nav-item {
        border-radius: $border-radius;

        .nav-link {
          color: $white;
          font-weight: 600;
          border-radius: $border-radius;

          i {
            width: 20px;
          }

        }

        .nav-link.active {
          color: $white;
          background: $gray-8;
        }

      }

    }

  }

}

.agency-management {
  tr {
    td {
      padding: 30px;
    }

  }

}

// nav-tabs-03
.nav-tabs-03 {
  display: flex;
  border-bottom: 2px solid $border-color;

  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      text-align: left;
      text-transform: capitalize;
      margin-bottom: -2px;

      span {
        width: 50px;
        height: 50px;
        line-height: 46px;
        display: inline-block;
        text-align: center;
        border: 2px solid $border-color;
        border-radius: 50%;
        margin-right: 14px;
      }

    }

    .nav-link.active {
      span {
        border: 2px solid $primary;
        background: $primary;
        color: $white;
      }

    }

  }

}
