// Grid
.property-item {
	margin-bottom: 30px;
}

.property-image {
	position: relative;

	.property-agent {
		display: flex;
		align-items: flex-end;
		position: absolute;
		bottom: 0;
		margin: 20px;

		.property-agent-image {
			width: 38px;
			margin-right: 10px;
			border: 2px solid $white;
			border-radius: 100%;
			cursor: pointer;

			img {
				width: 100%;
				border-radius: 100%;
			}

		}

		.property-agent-info {
			opacity: 0;
			visibility: hidden;
			background: $white;
			padding: 10px;
			font-size: 13px;
			position: relative;
			border-radius: $border-radius;
			transition: all 0.3s ease-in-out;

			&:before {
				position: absolute;
				left: -9px;
				bottom: 15px;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 10px 5px 0;
				border-color: transparent $white transparent transparent;
			}

			.property-agent-name {
				color: $gray-8;
				font-weight: 600;

				&:hover {
					color: $primary;
				}

			}

			.property-agent-date {
				margin-left: auto;
			}

			.property-agent-contact {
				display: flex;
				margin-top: 10px;
				margin-bottom: 0;

				li {
					a {
						display: block;
						width: 30px;
						height: 30px;
						line-height: 30px;
						background: $gray-8;
						color: $white;
						font-size: 12px;
						text-align: center;
						border-radius: 50%;
						margin-right: 3px;

						&:hover {
							background: $primary;
						}

					}

				}

			}

		}


&:hover {
	.property-agent-info {
		opacity: 1;
		visibility: visible;
	}
}



	}

	.property-status {
		display: flex;
		position: absolute;
		right: 8px;
		top: 8px;
		margin-bottom: 0px;

		li {
			font-size: 13px;
			color: #fff;
			padding: 3px 8px;
			margin-right: 6px;

			&:last-child {
				margin-right: 0px;
			}

		}

		li.property-feature {
			background: $primary;
		}

		li.property-rent {
			background: $gray-8;
		}

	}

	.property-lable {
		display: flex;
		position: absolute;
		right: 8px;
		top: 8px;
	}

	.property-trending {
		&:before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 55px 55px 0 0;
			border-color: #ffc107 transparent transparent transparent;
			position: absolute;
			left: 0px;
			top: 0px;
		}

		.fas {
			position: absolute;
			top: 12px;
			left: 12px;
			color: #fff;
		}

	}

		.property-agent-popup {
			position: absolute;
			right: 0;
			bottom: 0;
			margin: 20px;
			a {
				background: $gray-8;
				padding: 4px 6px;
				color: $white;
				border-radius: $border-radius;

				&:hover {
					background: $primary;
				}

				i{
					margin-right: 2px;
				}
			}
			}

}

.property-details {
	background: $white;
	border: 1px solid $border-color;
	border-top: none;

	i {
		margin-right: 5px;
	}

	.property-info {
		margin-bottom: 0px;

		li {
			padding: 15px 0 0;
			font-size: 13px;

	 i {
			margin-right: 0;
			display: block;
			font-size: 18px;
			color: $gray-3;
	}


			span {
				color: $gray-3;
				font-size: 22px;
				margin-left: 3px;
				font-weight: 300;
			}

		}

	}

	.property-details-inner {
		padding: 20px;

		.property-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.property-address {
			margin-right: 10px;
		}

	}

	 .property-price {
			color: $primary;
			font-size: 24px;
			font-weight: 600;
			font-family: $font-hedding;
			margin-top: 14px;

			span {
				font-size: 14px;
				color: $gray-3;
				font-weight: normal;
			}
		}




.property-btn {

	display: flex;

		.property-link {
			margin-right:auto;
			width: 100%;
		}

	.property-listing-actions {
		display: flex;
		border-top: 1px solid $border-color;
		border-bottom: 0px solid $border-color;



		li {
			display: flex;
			position: relative;

			&:before {
				content: "";
				border-right: 1px solid $border-color;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
			}

			&:first-child {
				&:before {
					content: none;
				}

			}

			a {
				align-self: center;
				color: $gray-3;
				padding: 12px 20px;
				background: transparent;

				&:hover {
					color: $primary;
				}

			}

			i {
				margin-right: 0px;
			}

		}

	}



	}

	.property-btn {
		margin-top: auto;
		a {
			display: block;
			padding: 15px;
			font-size: 15px;
			text-align: center;
			background: $gray-1;
			color: $gray-8;

			&:hover {
				background: $primary;
				color: $white;
			}

		}

	}

}


.property-filter-tag {
		display: flex;
	  justify-content: flex-end;
	 ul {
	 	display: flex;
	 	li {
				a {
					margin-left: 3px;
					background: rgba($primary, 0.1);
					color: $primary;
					font-weight: 500;
					padding: 2px 8px;
					font-size: 13px;
					border-radius: $border-radius;

					i {
						padding-left: 4px;
						font-size: 12px;
					}

					&:hover {
							background: $primary;
							color: $white;
					}


				}

				.filter-clear {
						background: rgba($gray-9, 0.1);
						color: $gray-9;

						&:hover {
							background: $gray-9;
							color: $white;
					}
					}
	 	}
	 }
}

// List
.property-col-list {
	.property-details {
		display: flex;
		flex-direction: column;
		height: 100%;
		border-top: 1px solid $border-color;
		border-left: none;

		.property-agent {
			padding: 0px 20px 20px;
			margin-top: auto;
		}

		.property-price {
			margin-top: 0;
		}

		.property-details-inner-box {
			display: flex;
			align-items: flex-start;
			flex-wrap:wrap;

			.property-price{
				margin-left: auto;
			}
		}

	}

}

// Property Offter
.property-offer {
	.property-offer-image {
		padding: 90px 80px;
	}

	.property-details {
		.property-agent {
			padding: 0px 20px 20px;
		}

	}

}

.property-map {
	.map-canvas {
		height: 500px;
	}

}

// Property Details Page
.property-list {
	li {
		margin-bottom: 5px;
		b {
			color: $gray-8;
			font-weight: 600;
			margin-right: 3px;
		}

	}

}

.property-list-style-2 {
	li {
		display: flex;
		align-items: center;
		line-height: 30px;

		&:before {
			content: "\f111";
			font-family: "Font Awesome 5 Free";
			font-style: normal;
			font-weight: 900;
			color: $primary;
			font-size: 5px;
			margin-right: 10px;
		}

	}

}

.walk-score-info {
	align-items: center;
}

.property-nearby {
	.nearby-title {
		font-size: 16px;
	}

}

.property-search-field {
	.property-search-item {
		position: relative;
		border: 1px solid $border-color;
		border-bottom: 0;
	 z-index: 8;
	}

	.row {
		margin: 0px;
	}

	.row.property-price-slider {
		border-top: 1px solid $border-color;
	}

	.form-group {
		padding: 24px 28px;
		margin-bottom: 0px;
		border-left: 1px solid $border-color;
		border-bottom: 1px solid $border-color;

		&:first-child {
			border-left: none;
		}

		label {
			display: block;
		}

	}

	.form-control {
		background: transparent;
		color: $gray-8;
		padding: 0px;
		height: auto;
		line-height: normal;
		border: none;

		&::placeholder {
			color: $gray-8;
		}

	}

	i {
		padding-right: 5px;
	}

	.more-search {
		color: $gray-3;
		display: block;

		&:hover {
			color: $primary;
		}

	}

	.form-group-search {
		width: 100%;

		i {
			color: $primary;
		}

	}

	.advanced-search {
		position: absolute;
		left: 0px;
		right: 0px;
		top: 100%;
		z-index: 99;
		margin: -1px 0px;
		width: 100%;
		
	}

	.card {
		padding: 0px;
		border-radius: 0px;
		border-color: $border-color;
	}

}

// Property Filter
.property-filter {
	border: 1px solid $border-color;
	position: relative;
	align-items: center;

	 &:after {
      position: absolute;
      left: 0;
      width: 100%;;
      top: -4px;
      height: 1px;
      content: "";
      background: $border-color;
    }
    &:before {
      position: absolute;
      bottom: -4px;
      width: 100%;
      left: 0;
      height: 1px;
      content: "";
      background: $border-color;
    }

	ul {
		li {
			padding: 8px 14px;
			    display: flex;

			a {
				color: $body-color;
				font-size: 12px;

				&:hover {
					color: $primary;
				}

			}

		}

		li.active {
			a {
				span {
					background: $primary;
				}
			}


		}

		&:nth-child(2n+2) {
			li {
				&:last-child {
					border-right: none;
					padding-left: 0;
				}

			}

		}

	}

	.property-short {
		.select2-container {
			min-width: 130px;
			margin-left: 10px;
		}
	}

.property-view-list {
			margin-left: auto;
			align-items: center;
		}


.property-list-icon {
				display: block;
				background: rgba($primary, 0.1);
				padding: 10px 10px 8px;
				border-radius: $border-radius;
			span {
				margin: 0 auto;
				background: $primary;
				height: 2px;
				width: 10px;
				border-radius: 3px;
				margin-bottom: 2px;
				display: block;

				&:nth-child(2) {
					width: 16px;
				}
			}

			&:hover, .active {
					background: $primary;
					span {
						background: $white;
					}
			}

		}

.property-list-icon.active {
		background: $primary;
					span {
						background: $white;
					}
		}

		.property-grid-icon {
			display: flex;
			background: rgba($primary, 0.1);
				color: $primary;
				padding: 10px 10px 8px;
				border-radius: $border-radius;

			span {
				background: $primary;
				width: 2px;
				height: 10px;
				border-radius: 3px;
				margin: 0 1px;
				display: inline-block;

				&:nth-child(2) {
					height: 16px;
					margin-top: -3px;
				}
			}

		&:hover {
					background: $primary;
					span {
						background: $white;
					}
			}
		}

		.property-grid-icon.active {
			background: $primary;
					span {
						background: $white;
					}
		}


}

// property-detail
.property-detail-meta {
	display: flex;
	margin: 20px 0 30px;

	> li {
		> a {
			margin-right: -1px;
			border: 1px solid $border-color;
			padding: 14px 20px;
			display: block;
			color: $gray-3;

			&:hover {
				color: $primary;
			}

		}

	}

}

.share-box {
	position: relative;

	.share-box-social {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		left: 50%;
		bottom: 100%;
		display: flex;
		background: $primary;
		padding: 10px;
		transition: all 0.3s ease-in-out;
		border-radius: $border-radius;
		transform: translate(-50%, 0px);

		li {
			a {
				padding: 0 10px;
				color: $white;
				font-size: 13px;
				display: block;

				&:hover {
					color: $gray-8;
				}

			}

		}

		&:before {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 6px 0 6px;
			border-color: $primary transparent transparent transparent;
			content: "";
			position: absolute;
			left: 50%;
			bottom: -6px;
			z-index: 99;
			margin-left: -3px;
		}

	}

	&:hover {
		.share-box-social {
			opacity: 1;
			visibility: visible;
			margin-bottom: 6px;
		}

	}

}

.property-detail-gallery {
	position: relative;

	.nav-tabs.nav-tabs-02 {
		position: absolute;
		z-index: 8;
		top: 20px;
		left: 20px;

		.nav-item {
			margin-right: 4px;
		}

	}

}

.property-detail-gallery .slider-slick,
.property-detail-gallery iframe,
.property-detail-gallery #street-view {
	min-height: 500px;
}

// property item map
.si-content-wrapper {
	padding: 0;
}

.custom-window.open {
	.si-frame {
		box-shadow: none;
		overflow: visible;
	}

}

.map-canvas {
	width: 100%;
	height: 100%;
}

.custom-window {
	top: 30px;
	width: 280px;
	-webkit-transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	opacity: 0;
	visibility: hidden;

	.si-content {
		overflow: visible;
	}

}

.custom-window.active {
	top: 0;
	opacity: 1;
	visibility: visible;
}

.property-item-map {
	position: relative;
	max-height: inherit;
	padding: 180px 12px 12px 12px;
	box-shadow: 0 4px 16px rgba(black, 0.1);
	overflow: auto;
	z-index: 2;
}

.si-pointer-bg-top {
	box-shadow: 0 4px 16px rgba(black, 0.1);
}

.property-item-map-img {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 1;
}

.property-item-map-title {
	margin-top: 12px;
}

.property-item-map-price {
	margin-top: 8px;
	display: flex;
	align-items: center;
}

.property-item-map-content {
	p {
		margin: 0;
	}

	* {
		+ {
			p {
				margin-top: 1em;
			}

		}

	}

	a {
		color: $gray-3;

		&:hover {
			color: $primary;
		}

		&:focus {
			color: $primary;
		}

		&:active {
			color: $primary;
		}

	}

}

.custom-close {
	position: absolute;
	top: -8px;
	right: -8px;
	z-index: 9;
	width: 24px;
	padding: 0 8px;
	height: 24px;
	border-radius: 5px;
	-webkit-transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	border: 0;
	background-color: $primary;
	color: $white;
	font-size: 20px;
	line-height: 20px;
	cursor: pointer;
	transition: all 0.4s ease-in-out;

	&:hover {
		background-color: $gray-8;
	}

	&:focus {
		background-color: $gray-8;
	}

	&:active {
		background-color: $gray-8;
	}

}

// Property Search
.property-search-field-top {
	margin-top: -100px;
	position: relative;
	z-index: 99;
}

.property-search-field-top-02 {
	margin-top: -50px;
	position: relative;
	z-index: 2;
}

.property-search-field.property-search-field-02.property-search-field-top-03 {
	margin-top: -56px;
	position: relative;
	z-index: 8;

	.property-search-item {
		background: $gray-1;
		padding: 15px;

		.card {
			background: $gray-1;
		}

		.row {
			align-items: flex-end;

			.btn {
				margin-top: 0px;
			}

		}

	}

	.form-control {
		background: $white;
	}

}

// property search field below
.property-search-field.property-search-field-below {
	.advanced-search {
		position: relative;
		width: 100%;
		margin: -1px 0px;
	}

	.card {
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
	}

}

// property search field advanced
.property-search-field.property-search-field-advanced {
	.advanced-search {
		position: relative;
		width: 100%;
		margin: -1px 0px;
	}

	.card {
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
	}

}

// property search field 02
.property-search-field.property-search-field-02 {
	.property-search-item {
		border: none;

		.row {
			align-items: flex-end;

			.btn {
				margin-top: 30px;
			}

		}

	}

	.advanced-search {
		position: relative;
		width: 100%;
		margin: -1px 0px;
	}

	.card {
		border: none;
	}

	.form-group {
		border: none;
		padding: 10px 10px;
		align-items: center;
	}

	.form-control {
		background: transparent;
		color: $gray-8;
		padding: 14px 20px;
		height: auto;
		line-height: normal;
		border: 1px solid $border-color;

		&::placeholder {
			color: $gray-8;
		}

	}

	.select2-container--default {
		.select2-selection--single {
			border: 1px solid $border-color;
			height: 50px;
			padding: 15px 20px;
			border-radius: 3px;

			.select2-selection__arrow {
				top: 16px;
				right: 20px;
			}

		}

	}

}

.select2-search--dropdown .select2-search__field {
	padding: 8px 14px;
}
