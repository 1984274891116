.footer {
  .footer-contact-info
  ul {
    margin-bottom: 0;

    li {
      display: flex;
      padding-bottom: 10px;

      i {
        font-size: 18px;
        color: $primary;
        width: 20px;
      }

      span {
        padding-left: 10px;
        color: $white;
      }

    }

    li:last-child {
      padding-bottom: 0;
    }

  }

  .footer-link {
    ul {
      display: inline-block;
      padding-right: 16px;

      li {
        display: block;
        width: 100%;
        padding-bottom: 10px;

        a {
          color: $white;

          &:hover {
            color: $primary;
          }

        }

      }

    }

  }

  .footer-recent-List {
    ul {
      li {
        margin-bottom: 20px;

        .footer-recent-list-item {
          display: flex;

          img {
            height: 80px;
          }

          .footer-recent-list-item-info {
            padding-left: 20px;

            a {
              display: block;
              font-family: $font-hedding;
            }

            .address {
              color: $white;

              &:hover {
                color: $primary;
              }

            }

          }

        }

        &:last-child {
          margin-bottom: 0;
        }

      }

    }

  }

  .footer-bottom {
    background: lighten($gray-9, 2%);
    padding: 20px 0;
    margin-top: 60px;

    .footer-logo {
      height: 40px;
    }

  }

}
