// Font family
$font-base: 		'Roboto', sans-serif;;
$font-hedding:	'Barlow Semi Condensed', sans-serif;

// Colors
$body-color:		#969696;
$primary:				#26ae61;
$white:					#ffffff;
$gray-1: 				#fafafa; 	// bg light
$gray-2: 				#dfdfdf;
$gray-3: 				#969696; 	// body-text
$gray-4: 				#707173;
$gray-5: 				#3a4957;
$gray-6: 				#293745;
$gray-7: 				#1d2936;
$gray-8: 				#001935; 	// Hedding color
$gray-9: 				#001022; 	// bg color
$black:  				#000000;

$border-color: 	#eeeeee;


// For button and input border radius
$border-radius: 3px;
